/*global google*/
import React, { useState, useEffect } from "react"
import Footer from "../../components/footer"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Geocode from "react-geocode"
import AppStore from "../../images/appstore.svg"
import PlayStore from "../../images/playstore.svg"
import DataTable from "react-data-table-component"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow,
} from "react-google-maps"
import Modal from "../../components/Modal"
import { WeriseServiceAPI } from "../../services";

const downloadApp  = (url) => {
  if(typeof window !== "undefined"){
    window.open(url, '_blank', "noreferrer, noopener");
  }
}

const columns = [
  {
    name: "County",
    cell: row => (
      <>
        <span style={{ color: "#E77C2E" }}>{row?.county}</span>
      </>
    ),
    width: "200px",
    sortable: true,
  },
  {
    name: "Category",
    cell: row => (
      <>
        <span style={{ color: "#E77C2E" }}>{row?.category}</span>
      </>
    ),
    width: "200px",
    sortable: true,
  },
  {
    name: "Municipal Name",
    cell: row => (
      <>
        <span style={{ color: "#E77C2E" }}>{row?.municiple_name}</span>
      </>
    ),
    width: "260px",
    sortable: true,
  },
  {
    name: "Seat",
    cell: row => (
      <>
        <span style={{ color: "#E77C2E" }}>{row?.seat}</span>
      </>
    ),
    width: "260px",
    sortable: true,
  },
  {
    name: "Zip Code",
    cell: row => (
      <>
        <span style={{ color: "#E77C2E" }}>{row?.zip_code}</span>
      </>
    ),
    width: "250px",
    sortable: true,
  },
  {
    name: " Polling Place",
    cell: row => (
      <>
        <span style={{ color: "#E77C2E" }}>{row?.polling_place}</span>
      </>
    ),
    width: "280px",
    sortable: true,
  },
]
const MapWrapper = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={props?.firstPath?.lat ? 15 : 5}
      defaultCenter={{
        lat: Number(props?.firstPath?.lat) || 43.7844,
        lng: Number(props?.firstPath?.lng) || -88.7879,
      }}
      defaultOptions={{
        scrollwheel: false,
        styles: [
          {
            featureType: "administrative",
            elementType: "labels.text.fill",
            stylers: [{ color: "#444444" }],
          },
          {
            featureType: "landscape",
            elementType: "all",
            stylers: [{ color: "#f2f2f2" }],
          },
          {
            featureType: "poi",
            elementType: "all",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "road",
            elementType: "all",
            stylers: [{ saturation: -100 }, { lightness: 45 }],
          },
          {
            featureType: "road.highway",
            elementType: "all",
            stylers: [{ visibility: "simplified" }],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            elementType: "all",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: [{ color: "#5e72e4" }, { visibility: "on" }],
          },
        ],
      }}
    >
      <Marker
        // icon={
        //   "https://res.cloudinary.com/dksyt7yvf/image/upload/v1638889494/Group_126_bys2zd.png"
        // }
        // position={{
        //   lat: 43.7844,
        //   lng: -88.7879,
        // }}
        // TODO: Change to user location
        position={{
          lat: Number(props?.userLat),
          lng: Number(props?.userLong),
        }}
      >
        <InfoWindow>
          <div>Your Location</div>
        </InfoWindow>
      </Marker>
      <Marker
        position={{
          lat: Number(props?.firstPath?.lat),
          lng: Number(props?.firstPath?.lng),
        }}
      >
        {props?.firstPath?.lat ? (
          <InfoWindow>
            <div>Polling Place</div>
          </InfoWindow>
        ) : (
          ""
        )}
      </Marker>

      <Polyline
        path={props?.lineCoordinates}
        geodesic={false}
        options={{
          strokeColor: "#F17F39",
          strokeOpacity: 10,
        }}
      />
    </GoogleMap>
  ))
)

export default function VotingPolls() {
  const [polls, setPolls] = useState([])
  const [resultPolls, setResultPolls] = useState([])
  const [seat, setSeat] = useState("")
  const [selectPolls, setSelectPolls] = useState([])
  const [category, setCategory] = useState("")
  const [county, setCounty] = useState("")
  const [municipal, setMunicipal] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const [index, setIndex] = useState(0)
  const [firstPath, setFirstPath] = React.useState(null)

  const [countyOptions, setCountyOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const [municipalOptions, setMunicipalOptions] = useState([])
  const [seatOptions, setSeatOptions] = useState([])

  const [userLat, setUserLat] = useState(null)
  const [userLong, setUserLong] = useState(null)

  const resetPollingPlaces = async () => {
    try {
      const res = await WeriseServiceAPI.searchPollingPlaces()
      if (res?.status === 200 && res?.data?.status === "success") {
        setSelectPolls(res?.data?.pollingPlaces)
        // console.log({ res })
      }
    } catch (error) {
      console.error({ error })
    }
  }

  const getPollingPlaces = async () => {
    try {
      const res = await WeriseServiceAPI.searchPollingPlaces()
      if (res?.status === 200 && res?.data?.status === "success") {
        setSelectPolls(res?.data?.pollingPlaces)
        const duplicateCounties = []
        res?.data?.pollingPlaces?.forEach(itm => {
          duplicateCounties.push(itm?.county)
        })
        setCountyOptions([...new Set(duplicateCounties)])
      }
    } catch (error) {
      console.error({ error })
    }
  }

  const getCategoryOptions = opt => {
    setCounty(opt)

    const countiesFilter = selectPolls?.filter(poll => poll?.county === opt)

    setSelectPolls(countiesFilter)
    const duplicateCategories = []

    countiesFilter.forEach(itm => {
      duplicateCategories.push(itm?.category)
    })

    setCategoryOptions([...new Set(duplicateCategories)])
  }

  const getMunicipalOptions = opt => {
    setCategory(opt)

    const categoriesFilter = selectPolls.filter(poll => poll?.category === opt)

    setSelectPolls(categoriesFilter)
    const duplicateMunicpalities = []

    categoriesFilter.forEach(itm => {
      duplicateMunicpalities.push(itm?.municiple_name)
    })

    setMunicipalOptions([...new Set(duplicateMunicpalities)])
  }

  const getSeatOptions = opt => {
    setMunicipal(opt)

    const municipalsFilter = selectPolls.filter(
      poll => poll?.municiple_name === opt
    )

    setSelectPolls(municipalsFilter)
    const duplicateSeats = []

    municipalsFilter.forEach(itm => {
      duplicateSeats.push(itm?.seat)
    })

    setSeatOptions([...new Set(duplicateSeats)])
  }

  const getResults = opt => {
    setSeat(opt)

    const seatsFilter = selectPolls.filter(poll => poll?.seat === opt)

    setSelectPolls(seatsFilter)
    setPolls(seatsFilter)
    setResultPolls(seatsFilter)
    // console.log({ seatsFilter })
  }
  const [lineCoordinates, setLineCoordinates] = React.useState(null)
  const isBrowser = () => typeof window !== "undefined"
  const checkWindow = isBrowser() && window?.google
  // console.log({ checkWindow })
  useEffect(() => {
    getPollingPlaces()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // console.log({ window })
    if (typeof window.google !== "undefined") {
      const google = window.google

      // console.log({ google })
      const directionsService = new google.maps.DirectionsService()

      // console.log({ directionsService })

      //get users location
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(pos => {
          const coords = pos.coords
          // console.log({ coords })
          setUserLat(coords.latitude)
          setUserLong(coords.longitude)
        })
      }

      // state origin and destination

      // const origin = { lat: 43.7844, lng: -88.7879 }
      const origin = { lat: Number(userLat), lng: Number(userLong) }

      // console.log({origin})

      // const origin = { lat: 6.612262899999999, lng: 3.3359974 };
      // const origin = { lat: 43.788, lng: -88.7891 }
      const destination = {
        lat: Number(firstPath?.lat),
        lng: Number(firstPath?.lng),
      }

      // const destination = { lat: Number(userLat), lng: Number(userLong) };
      // console.log({origin})
      // console.log({destination})
      directionsService?.route(
        {
          origin: origin,
          destination: destination,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            // console.log("new result", result)
            // console.log({result})
            const overViewCoords = result?.routes[0]?.overview_path
            setLineCoordinates(overViewCoords)
          } else {
            console.error(`error fetching directions ${result}`)
          }
        }
      )
    } else {
      // console.log({ window })
    }
  }, [checkWindow, firstPath, resultPolls])

  useEffect(() => {
    const getFirstPath = () => {
      Geocode.fromAddress(
        resultPolls?.[0]?.polling_place,
        "AIzaSyBGv53NEoMm3uPyA9U45ibSl3pOlqkHWN8"
      ).then(
        response => {
          // console.log(response?.results?.[0]?.geometry?.location)
          setFirstPath(response?.results?.[0]?.geometry?.location)
        },
        error => {
          console.error(error)
        }
      )
    }
    getFirstPath()
  }, [resultPolls])

  // console.log({ lineCoordinates })
  const getSteps = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <div className="w-full xl:w-1/3 mx-auto my-6 xl:my-2">
            <select
              onChange={e => getCategoryOptions(e.target.value)}
              value={county}
              className="w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg cursor-pointer focus:outline-none  sm:text-sm"
              name=""
              id=""
            >
              <option value="">Select County</option>
              {countyOptions.map((label, typeIdx) => (
                <option key={typeIdx} value={label}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        )
      case 1:
        return (
          <div className="w-full xl:w-1/3 mx-auto my-6 xl:my-2">
            <select
              disabled={!county}
              onChange={e => getMunicipalOptions(e.target.value)}
              value={category}
              className="w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg cursor-pointer focus:outline-none  sm:text-sm"
              name=""
              id=""
            >
              <option value="">Select Category</option>
              {categoryOptions.map((label, typeIdx) => (
                <option key={typeIdx} value={label}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        )
      case 2:
        return (
          <div className="w-full xl:w-1/3 mx-auto my-6 xl:my-2">
            <select
              disabled={!category}
              onChange={e => getSeatOptions(e.target.value)}
              value={municipal}
              className="w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg cursor-pointer focus:outline-none  sm:text-sm"
              name=""
              id=""
            >
              <option value="">Select Municipal</option>

              {municipalOptions.map((label, typeIdx) => (
                <option key={typeIdx} value={label}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        )

      case 3:
        return (
          <div className="w-full xl:w-1/3  mx-auto my-6 xl:my-2">
            <select
              disabled={!municipal}
              onChange={e => getResults(e.target.value)}
              value={seat}
              className="w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg cursor-pointer focus:outline-none  sm:text-sm"
              name=""
              id=""
            >
              <option value="">Select Seat</option>

              {seatOptions.map((label, typeIdx) => (
                <option key={typeIdx} value={label}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        )
      default:
        return "Error Occured"
    }
  }

  return (
    <>
      <Layout>
        <Seo title="Voting Polls" />
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} />
        <div className="max-w-full xl:min-h-screen flex p-5 flex-col md:flex-col xl:flex-row">
          <div className="flex flex-col xl:w-3/6 hero-pattern bg-hero-pattern p-10 text-center bg-cover">
            <h1 className="antialiased font-bold text-4xl md:text-7xl pt-10 text-white">
              Voting Polls
            </h1>
            <span className="py-20 text-md text-left font-medium text-white">
              Get to know where voting is taking place.
            </span>

            <div className="flex flex-col xl:flex-row xl:justify-around mt-10 justify-between">
              <div onClick={() => downloadApp('https://apps.apple.com/ng/app/werise-empowerment/id1610295267')} className="rounded-2xl border-2  my-2 xl:my-0 bg-white flex items-center justify-center border-primary p-3 cursor-pointer">
                <AppStore />
                <div className="flex ml-5 gap-1 flex-col">
                  <span className="">App Store</span>
                  {/* <span className="text-xs">Coming Soon</span> */}
                  </div>
              </div>
              <div onClick={() => downloadApp('https://play.google.com/store/apps/details?id=com.werise')} className="rounded-2xl border-2 flex bg-white items-center justify-center border-primary p-3 cursor-pointer">
                <PlayStore />
                <span className="ml-3">Play Store</span>
              </div>
            </div>
          </div>
          <div className="relative w-full h-full">
            <MapWrapper
              // googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBGv53NEoMm3uPyA9U45ibSl3pOlqkHWN8"
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBGv53NEoMm3uPyA9U45ibSl3pOlqkHWN8&v=3.exp&libraries=geometry,drawing,places"
              // googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyBGv53NEoMm3uPyA9U45ibSl3pOlqkHWN8&libraries=places&callback=initMap'
              loadingElement={<div style={{ height: `100%`, width: `100%` }} />}
              containerElement={
                <div
                  style={{ height: `800px` }}
                  className="map-canvas"
                  id="map-canvas"
                />
              }
              userLat={userLat}
              userLong={userLong}
              firstPath={firstPath}
              lineCoordinates={lineCoordinates}
              // origin={origin}
              mapElement={
                <div style={{ height: `100%`, borderRadius: "inherit" }} />
              }
            />

            <div className="absolute bottom-0 h-60 px-5 left-0 w-5/6 bg-white bg-opacity-90 z-50">
              <div className="flex justify-center my-6">{getSteps(index)}</div>
              <div className="flex flex-col lg:flex-row gap-4 justify-center mt-10">
                {index > 0 && index <= 2 && (
                  <button
                    type="button"
                    disabled={!county}
                    onClick={() => setIndex(index - 1)}
                    className="text-white bg-primary rounded-md py-2 xl:ml-10 w-full xl:my-0 my-10 xl:w-1/2"
                  >
                    Previous
                  </button>
                )}
                {index <= 2 && (
                  <button
                    type="button"
                    disabled={!county}
                    onClick={() => setIndex(index + 1)}
                    className="text-white bg-primary rounded-md py-2 xl:ml-10 w-full xl:my-0 my-10 xl:w-1/2"
                  >
                    Next
                  </button>
                )}
                {index === 3 && (
                  <button
                    type="button"
                    onClick={() => {
                      setSeat("")
                      setCategory("")
                      setCounty("")
                      setMunicipal("")
                      resetPollingPlaces()
                      setIndex(0)
                    }}
                    className="text-white bg-primary rounded-md py-2 xl:ml-10 w-full xl:my-0 my-10 xl:w-1/2"
                  >
                    Back
                  </button>
                )}
                {index === 3 && (
                  <button
                    type="button"
                    onClick={() => {}}
                    className="text-white bg-primary rounded-md py-2 xl:ml-10 w-full xl:my-0 my-10 xl:w-1/2"
                  >
                    Search Polling Places
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="p-5 mx-w-full my-20">
          <DataTable
            columns={columns}
            // progressPending={isLoading}
            persistTableHead
            // progressComponent={<Loader />}
            data={polls}
            pagination
          />
        </div>
        <div>
          <Footer />
        </div>
      </Layout>
    </>
  )
}
